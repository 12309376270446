<template>
  <CrossgridAuthLayout>
    <template #main>
      <CrossgridCard
        title="Create your free account"
        description=""
        :logo="true"
        :signup-flow="true"
        size="sm:max-w-md"
        :errors="errors"
        :action-button-disabled="actionButtonDisabled"
        :is-loading="isLoading"
        @cancel-click="handleNavigation('back')"
        @action-click="handleNavigation('next')">
        <template #main>
          <div class="col-span-full">
            <CrossgridLabel id="email" label="Email" />
            <CrossgridInput
              id="email"
              v-model="formData.email"
              :data-e2e="'email'"
              type="text"
              autocomplete="email"
              placeholder="Enter your email"
              :errors="errors" />
          </div>

          <div class="col-span-full">
            <CrossgridLabel id="password1" label="Password" />
            <CrossgridInput
              id="password1"
              v-model="formData.password1"
              :data-e2e="'password'"
              type="password"
              autocomplete="new-password"
              placeholder="Create a password"
              :errors="errors" />
          </div>

          <div class="col-span-full">
            <div class="flex items-center">
              <div class="flex items-center">
                <input
                  id="terms-conditions"
                  v-model="termsConditions"
                  :data-e2e="'terms-conditions'"
                  aria-describedby="item-conditions-description"
                  name="terms-conditions"
                  type="checkbox"
                  class="h-4 w-4 rounded border-border-primary bg-white text-bg-brand-solid focus:ring-bg-brand-solid dark:border-border-primary-dark dark:bg-bg-primary-dark dark:text-bg-brand-solid-dark dark:focus:ring-bg-brand-solid-dark" />
              </div>
              <div class="ml-2 text-sm">
                <label
                  for="terms-conditions"
                  class="text-text-tertiary dark:text-text-tertiary-dark"
                  >I agree to Crossgrid's
                  <a
                    class="text-text-brand-primary"
                    :href="CROSSGRID_PAGE_URLS.external.terms_of_service"
                    target="_blank"
                    >Terms & Conditions</a
                  ></label
                >
              </div>
            </div>
          </div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridAuthLayout>
</template>

<script setup lang="ts">
import {computed, onBeforeMount, reactive, ref, Ref} from 'vue';
import {useUserStore} from '@/stores/userStore';
import {useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {APIStandardError} from '@/types/network';
import {ProSignupStepFour} from '@/types/pro-signup';
import {useHttp} from '@/utils/composables';
import {getProSignupSavedProgress} from '@/utils/pro-signup';

import CrossgridAuthLayout from '@/components/_layout/CrossgridAuthLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';
import CrossgridInput from '@/components/atoms/CrossgridInput.vue';
import CrossgridLabel from '@/components/atoms/CrossgridLabel.vue';

const router = useRouter();
const http = useHttp();

const formData = reactive<ProSignupStepFour>({} as ProSignupStepFour);
const errors: Ref<APIStandardError | undefined> = ref(undefined);
const termsConditions = ref<boolean>(false);
const isLoading = ref(false);

const userStore = useUserStore();

const createAccount = () => {
  http
    .post('/pro/pro_signup/create_account/', formData)
    .then(() => {
      userStore.fetchUserSession();
      return router.push(CROSSGRID_PAGE_URLS.pro.signup.success);
    })
    .catch(error => {
      errors.value = error.response?.data;
      const apiErrors: APIStandardError = error.response?.data;
      let unexpectedError = false;

      if (apiErrors.validation_error) {
        apiErrors.validation_error.forEach(error => {
          // Any other keys but email and password will trigger a unexpected error message
          if (!['email', 'password1'].includes(error.id)) {
            unexpectedError = true;
          }
        });
      }
      errors.value = apiErrors;
      if (unexpectedError) errors.value = {unexpected_error: 'Unexpected data corruption error'};
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const handleNavigation = (action: string) => {
  if (action === 'back') return router.push(CROSSGRID_PAGE_URLS.pro.signup.step_three);
  const progress = getProSignupSavedProgress();
  if (progress && progress.pro_three) {
    Object.assign(formData, progress.pro_three);
    isLoading.value = true;
    createAccount();
  }
};

const actionButtonDisabled = computed(() => {
  return (
    Object.values(formData).length < 2 ||
    Object.values(formData).some(value => value === '') ||
    !termsConditions.value
  );
});

onBeforeMount(() => {
  // Make sure that step 3 is done, otherwise redirect user back
  const progress = getProSignupSavedProgress();
  if (!progress || !progress.pro_three)
    return router.push(CROSSGRID_PAGE_URLS.pro.signup.step_three);
});
</script>
