<template>
  <CrossgridInput
    :id="id"
    type="text"
    autocomplete="address-line1"
    :value="modelValue"
    :placeholder="placeholder"
    :help-text="helpText"
    :errors="errors"
    @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)" />
</template>

<script setup lang="ts">
import {onMounted, reactive} from 'vue';
import {autofill, confirmAddress} from '@mapbox/search-js-web';

import {AddressBase} from '@/types/common';
import {APIStandardError} from '@/types/network';
import {createAddressAutofillMockForm} from '@/utils/pro-signup';

import CrossgridInput from '@/components/atoms/CrossgridInput.vue';

const address = reactive<AddressBase>({} as AddressBase);

type Props = {
  placeholder?: string;
  id?: string;
  modelValue?: string;
  helpText?: string;
  errors?: APIStandardError;
};

withDefaults(defineProps<Props>(), {
  placeholder: 'Your placeholder',
  id: undefined,
  modelValue: undefined,
  helpText: undefined,
  errors: undefined,
});

const emits = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'autocompleted-address', value: AddressBase): void;
}>();

const mapboxAccessToken = import.meta.env.VITE_MAPBOX_TOKEN;

const initAutofill = () => {
  const autoFilledValue = autofill({
    accessToken: mapboxAccessToken,
    options: {country: 'us'},
  });

  autoFilledValue.addEventListener('retrieve', async event => {
    const {
      address_line1,
      address_line2,
      address_level1,
      address_level2,
      address_level3,
      postcode,
      country_code,
    } = event.detail.features[0].properties;

    address.address_1 = address_line1;
    address.address_2 = address_line2;
    address.locality_level_1 = address_level1;
    address.locality_level_2 = address_level2;
    address.locality_level_3 = address_level3;
    address.postal_code = postcode;
    address.country = country_code;
    address.latitude = event.detail.features[0].geometry.coordinates[0];
    address.longitude = event.detail.features[0].geometry.coordinates[1];

    // Create mock form with input values that Mapbox expects, so we can run confirmAddress()
    const mockForm = createAddressAutofillMockForm(address);

    const result = await confirmAddress(mockForm, {
      accessToken: mapboxAccessToken,
      minimap: true,
      skipConfirmModal: feature =>
        ['exact', 'high'].includes(feature.properties.match_code.confidence),
    });

    if (result.type === 'change') {
      // If the user accepts the change, we re-assign it to the address object
      const {
        address_line1,
        address_line2,
        address_level1,
        address_level2,
        address_level3,
        postcode,
        country_code,
      } = result.feature.properties;

      address.address_1 = address_line1;
      address.address_2 = address_line2;
      address.locality_level_1 = address_level1;
      address.locality_level_2 = address_level2;
      address.locality_level_3 = address_level3;
      address.postal_code = postcode;
      address.country = country_code;
      address.latitude = result.feature.geometry.coordinates[0];
      address.longitude = result.feature.geometry.coordinates[1];
    }

    emits('autocompleted-address', address);
  });
};

onMounted(() => {
  initAutofill();
});
</script>
