<template>
  <section class="hidden w-1/3 bg-bg-tertiary dark:bg-bg-secondary-dark md:block">
    <ul>
      <li
        v-for="category in primaryCategories"
        :key="category.name"
        :class="[
          'm-2 rounded-xl text-sm hover:bg-bg-secondary dark:bg-bg-secondary-dark hover:dark:bg-bg-tertiary-dark',
          category.name === route.name
            ? 'bg-bg-primary text-text-primary dark:bg-bg-tertiary-dark dark:text-text-primary-dark'
            : 'dark:text-text-secondary-dark',
        ]">
        <RouterLink :to="{name: category.name}" class="flex items-center space-x-3 p-2.5">
          <component :is="category.icon" class="h-5 w-5 shrink-0" aria-hidden="true" />
          <div>
            {{ category.name }}
          </div>
        </RouterLink>
      </li>
      <div class="mx-5 border-b border-border-primary dark:border-border-primary-dark"></div>
      <li>
        <ul>
          <li
            v-for="category in secondaryCategories"
            :key="category.name"
            :class="[
              'mx-3 my-2 rounded-xl text-sm hover:bg-bg-secondary dark:bg-bg-secondary-dark hover:dark:bg-bg-tertiary-dark',
              category.name === route.name
                ? 'bg-bg-tertiary text-text-primary dark:bg-bg-tertiary-dark dark:text-text-primary-dark'
                : 'dark:text-text-secondary-dark',
            ]">
            <RouterLink :to="{name: category.name}" class="flex items-center space-x-3 p-3">
              <div>
                {{ category.name }}
              </div>
            </RouterLink>
          </li>
        </ul>
      </li>
    </ul>
  </section>
</template>

<script lang="ts" setup>
import {FunctionalComponent} from 'vue';
import {RouterLink, useRoute} from 'vue-router';

type Props = {
  primaryCategories: {
    name: string;
    count?: number;
    icon: FunctionalComponent;
  }[];
  secondaryCategories: {
    name: string;
    count?: number;
    icon?: FunctionalComponent;
  }[];
};

defineProps<Props>();

const route = useRoute();
</script>
