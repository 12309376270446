import 'vite/modulepreload-polyfill';
import './styles/index.css';

import {createApp} from 'vue';
import {browserTracingIntegration, init, replayIntegration} from '@sentry/vue';
import {createPinia} from 'pinia';

import App from './App.vue';
import router from './router';

const app = createApp(App);

if (['staging', 'production'].includes(import.meta.env.VITE_ENVIRONMENT)) {
  init({
    app,
    environment: import.meta.env.VITE_ENVIRONMENT,
    dsn: 'https://df46e942430036d19696ce9fc7e26ebd@o4506435144974336.ingest.sentry.io/4506757333188608',
    integrations: [
      browserTracingIntegration(),
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost'],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.use(createPinia());
app.use(router);

app.mount('#app');
