<template>
  <Combobox v-model="selectedValue" as="div">
    <ComboboxLabel
      v-if="label"
      class="mb-2 block text-sm font-medium leading-6 text-text-secondary dark:text-text-secondary-dark"
      >{{ label }}</ComboboxLabel
    >
    <div class="relative">
      <ComboboxInput
        data-e2e="pro-service-search-input"
        :placeholder="placeholder"
        :class="[
          direction === 'right'
            ? 'roundedr-md'
            : direction === 'left'
              ? 'rounded-l-md'
              : 'rounded-md',
          'w-full rounded-lg border-0 bg-bg-primary text-text-primary shadow-sm ring-1 ring-inset ring-border-primary placeholder:text-text-placeholder focus:ring-2 focus:ring-inset focus:ring-button-primary-border dark:bg-bg-primary-dark dark:text-text-primary-dark dark:ring-border-primary-dark dark:placeholder:text-text-placeholder',
        ]"
        :display-value="(service: any) => service?.name"
        @input="handleQueryChange" />

      <ComboboxOptions
        v-if="proServices?.length > 0"
        :data-e2e="'pro-service-search-input-dropdown'"
        :class="[
          'absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-bg-primary py-1 text-base shadow-lg focus:outline-none dark:bg-bg-primary-dark sm:text-sm',
          query === ''
            ? ''
            : 'ring-1 ring-border-secondary ring-opacity-5 dark:ring-border-secondary-dark',
        ]">
        <ComboboxOption
          v-for="(service, index) in proServices"
          :key="index"
          v-slot="{active, selected}"
          :value="service"
          as="template">
          <li
            :class="[
              'relative cursor-pointer select-none py-2 pl-3 pr-9 text-text-primary dark:text-text-primary-dark',
              active ? 'bg-bg-active dark:bg-bg-active-dark' : '',
            ]">
            <span :class="['block truncate', selected && 'font-semibold']">
              {{ service.name }}
            </span>

            <span
              v-if="selected"
              class="absolute inset-y-0 right-0 flex items-center pr-4 text-fg-brand-primary dark:text-fg-brand-primary-dark">
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script setup lang="ts">
import {ref, Ref, watch} from 'vue';
import {
  Combobox,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue';
import {CheckIcon} from '@heroicons/vue/20/solid';

import {ProService} from '@/types/pro-services';

type Props = {
  label?: string;
  direction?: 'default' | 'left' | 'right';
  modelValue: ProService | null;
  proServices: ProService[];
  placeholder?: string;
};

const props = defineProps<Props>();

const query: Ref<string> = ref('');
const selectedValue = ref(props.modelValue);

const handleQueryChange = (e: {target: HTMLInputElement}) => {
  query.value = e.target.value;
  emits('searchQuery', e.target.value);
};

const emits = defineEmits<{
  (e: 'searchQuery', value: string): void;
  (e: 'update:modelValue', value: ProService | null): void;
}>();

watch(selectedValue, newValue => {
  emits('update:modelValue', newValue);
});

watch(
  () => props.modelValue,
  newValue => {
    selectedValue.value = newValue;
  }
);
</script>
