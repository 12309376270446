import {AddressBase} from '@/types/common';
import {ProListingAddress} from '@/types/pro-listings';
import {ProProfileSetupProgress, ProSignupSavedProgress} from '@/types/pro-signup';

export const getProSignupSavedProgress = (): ProSignupSavedProgress | null => {
  const progress = localStorage.getItem('pro_signup_progress');
  if (progress) {
    return JSON.parse(progress) as ProSignupSavedProgress;
  }
  return null;
};

export const getProProfileSetupProgress = (): ProProfileSetupProgress | null => {
  const progress = localStorage.getItem('pro_profile_setup_progress');
  if (progress) {
    return JSON.parse(progress) as ProProfileSetupProgress;
  }
  return null;
};

export const createAddressAutofillMockForm = (
  address: ProListingAddress | AddressBase
): HTMLFormElement => {
  const form = document.createElement('form');

  const address_line1_input = document.createElement('input');
  const address_line2_input = document.createElement('input');
  const address_level1_input = document.createElement('input');
  const address_level2_input = document.createElement('input');
  const address_level3_input = document.createElement('input');
  const postcode_input = document.createElement('input');
  const country_code_input = document.createElement('input');

  address_line1_input.setAttribute('type', 'text');
  address_line1_input.setAttribute('autocomplete', 'address-line1');
  address_line1_input.value = address.address_1 || '';

  address_line2_input.setAttribute('type', 'text');
  address_line2_input.setAttribute('autocomplete', 'address-line2');
  address_line2_input.value = address.address_2 || '';

  address_level1_input.setAttribute('type', 'text');
  address_level1_input.setAttribute('autocomplete', 'address-level1');
  address_level1_input.value = address.locality_level_1 || '';

  address_level2_input.setAttribute('type', 'text');
  address_level2_input.setAttribute('autocomplete', 'address-level2');
  address_level2_input.value = address.locality_level_2 || '';

  address_level3_input.setAttribute('type', 'text');
  address_level3_input.setAttribute('autocomplete', 'address-level3');
  address_level3_input.value = address.locality_level_3 || '';

  postcode_input.setAttribute('type', 'text');
  postcode_input.setAttribute('autocomplete', 'postal-code');
  postcode_input.value = address.postal_code || '';

  country_code_input.setAttribute('type', 'text');
  country_code_input.setAttribute('autocomplete', 'country');
  country_code_input.value = address.country || '';

  form.appendChild(address_line1_input);
  form.appendChild(address_line2_input);
  form.appendChild(address_level1_input);
  form.appendChild(address_level2_input);
  form.appendChild(address_level3_input);
  form.appendChild(postcode_input);
  form.appendChild(country_code_input);

  return form;
};
