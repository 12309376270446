<template>
  <div class="py-10 lg:px-16">
    <div :class="size" class="mx-auto w-full">
      <div class="bg-bg-primary px-6 py-12 shadow dark:bg-bg-primary-dark sm:rounded-lg sm:px-12">
        <img v-if="logo" class="mx-auto mb-6 h-12" :src="crossgridIcon" alt="Crossgrid" />
        <h1
          v-if="title"
          class="mb-6 text-center text-3xl font-medium text-text-primary dark:text-text-primary-dark">
          <slot name="title">{{ title }}</slot>
        </h1>
        <p
          v-if="description"
          class="my-4 mb-6 text-center text-text-tertiary dark:text-text-tertiary-dark">
          {{ description }}
        </p>
        <component
          :is="mainContainerType"
          v-if="mainContent"
          class="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
          <CrossgridErrorMessage :errors="errors" />

          <slot name="main"></slot>

          <div class="col-span-full">
            <div
              v-if="actionButton || cancelButton"
              class="mt-3 flex flex-col justify-center gap-3">
              <button
                v-if="actionButton && !isLoading"
                :disabled="actionButtonDisabled"
                :data-e2e="'action-button'"
                type="button"
                class="text-md w-full rounded-md bg-button-primary-bg px-4 py-2.5 font-semibold text-button-primary-fg shadow-sm ring-1 ring-inset ring-button-primary-border hover:bg-button-primary-bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-button-primary-border disabled:cursor-not-allowed disabled:bg-bg-disabled disabled:text-text-disabled disabled:ring-border-disabled-subtle dark:disabled:bg-bg-disabled-dark dark:disabled:text-text-disabled-dark dark:disabled:ring-border-disabled-dark-subtle"
                @click="(e: Event) => emits('action-click', e)">
                {{ actionButton }}
              </button>
              <button
                v-else
                :data-e2e="'action-button-loading'"
                type="button"
                disabled
                class="text-md flex w-full items-center justify-center gap-x-2.5 rounded-md bg-button-primary-bg px-4 py-2.5 font-semibold text-button-primary-fg shadow-sm ring-1 ring-inset ring-button-primary-border focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-button-primary-border disabled:cursor-not-allowed">
                <div
                  class="h-5 w-5 animate-spin rounded-full border-2 border-solid border-button-primary-fg border-t-transparent"></div>
                Loading...
              </button>
              <button
                v-if="cancelButton"
                type="button"
                data-e2e="cancel-button"
                class="text-md w-full rounded-md bg-button-secondary-bg px-4 py-2.5 font-semibold text-button-secondary-fg shadow-sm ring-1 ring-inset ring-button-secondary-border hover:bg-button-secondary-bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-button-secondary-border dark:bg-button-secondary-bg-dark dark:text-button-secondary-fg-dark dark:ring-button-secondary-border-dark dark:hover:bg-button-secondary-bg-dark-hover dark:focus-visible:outline-button-secondary-border-dark"
                @click="(e: Event) => emits('cancel-click', e)">
                {{ cancelButton }}
              </button>
            </div>
          </div>
          <div v-if="signupFlow" class="col-span-full">
            <p class="text-center text-sm text-text-tertiary dark:text-text-tertiary-dark">
              Already have an account?
              <a
                :href="CROSSGRID_PAGE_URLS.allauth.login"
                class="font-semibold leading-6 text-button-primary-bg hover:text-button-primary-bg-hover dark:text-button-primary-bg dark:hover:text-button-primary-bg-dark-hover">
                Log in
              </a>
            </p>
          </div>
        </component>
      </div>
      <div v-if="supportBox" class="mt-14 text-center">
        <h2 class="text-lg font-semibold text-text-primary dark:text-text-primary-dark">
          Need help?
        </h2>
        <p class="dark:text-tertiary-dark mb-4 text-sm text-text-tertiary">
          Mon-Fri from 8am to 5pm.
        </p>
        <a
          class="font-semibold leading-6 text-button-primary-bg hover:text-button-primary-bg-hover dark:text-button-primary-bg dark:hover:text-button-primary-bg-dark-hover"
          :href="`tel:${CROSSGRID_SUPPORT.phone}`"
          >{{ CROSSGRID_SUPPORT.phone }}</a
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import crossgridIcon from '@/assets/images/crossgrid-icon.svg';

import {CROSSGRID_PAGE_URLS, CROSSGRID_SUPPORT} from '@/constants/common';
import {APIStandardError} from '@/types/network';

import CrossgridErrorMessage from '@/components/atoms/CrossgridErrorMessage.vue';

type Props = {
  title?: string | null;
  size?: 'md:max-w-2xl' | 'sm:max-w-lg' | 'sm:max-w-md';
  errors?: APIStandardError;
  supportBox?: boolean;
  signupFlow?: boolean;
  logo?: boolean;
  mainContent?: boolean;
  description?: string | null;
  actionButton?: string | null;
  cancelButton?: string | null;
  actionButtonDisabled?: boolean;
  mainContainerType?: 'div' | 'form';
  isLoading?: boolean;
};

withDefaults(defineProps<Props>(), {
  title: 'Card title',
  size: 'sm:max-w-lg',
  errors: undefined,
  supportBox: true,
  signupFlow: false,
  logo: false,
  mainContent: true,
  description: 'Your beautiful description here',
  actionButton: 'Next',
  cancelButton: 'Back',
  actionButtonDisabled: true,
  mainContainerType: 'div',
  isLoading: false,
});

const emits = defineEmits<{
  (e: 'action-click', value: Event): Event;
  (e: 'cancel-click', value: Event): Event;
}>();
</script>
