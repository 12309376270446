<template>
  <CrossgridAuthLayout>
    <template #main>
      <CrossgridCard
        title="Enter a description of the job you need done"
        description=""
        :errors="errors"
        :action-button-disabled="actionButtonDisabled"
        :is-loading="isLoading"
        @cancel-click="handleNavigation('back')"
        @action-click="handleNavigation('next')">
        <template #main>
          <!-- Add to form data -->
          <div class="sm:col-span-6">
            <CrossgridLabel id="job_description" label="Job Description" />
            <CrossgridInput
              id="job_description"
              v-model="formData.job_description"
              type="multiline"
              placeholder="Enter description of the job you need done"
              :errors="errors" />
          </div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridAuthLayout>
</template>

<script setup lang="ts">
import {computed, onMounted, reactive, ref, Ref} from 'vue';
import {useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {APIStandardError} from '@/types/network';
import {MinimalUser} from '@/types/user';
import {UserSignupSavedProgress, UserSignupStepThree} from '@/types/user-signup';
import {useHttp} from '@/utils/composables';
import {getSession} from '@/utils/shared';
import {getUserSignupSavedProgress} from '@/utils/user-signup';

import CrossgridAuthLayout from '@/components/_layout/CrossgridAuthLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';
import CrossgridInput from '@/components/atoms/CrossgridInput.vue';
import CrossgridLabel from '@/components/atoms/CrossgridLabel.vue';

const router = useRouter();
const http = useHttp();

const formData = reactive<UserSignupStepThree>({} as UserSignupStepThree);
const errors: Ref<APIStandardError | undefined> = ref(undefined);
const isLoading = ref(false);

const userSession: Ref<MinimalUser | null> = ref(null);

const addJob = (progress: UserSignupSavedProgress) => {
  const slug = userSession.value?.slug;
  const services_needed = progress.user_two;
  http
    .post('/user/job/', {...formData, slug, services_needed})
    .then(() => {
      progress.user_three = formData;
      localStorage.setItem('user_signup_progress', JSON.stringify(progress));
      return router.push(CROSSGRID_PAGE_URLS.user.job_search.success);
    })
    .catch(error => {
      const apiErrors: APIStandardError = error.response?.data;
      let hiddenFieldsError = false;

      if (apiErrors && apiErrors.validation_error) {
        apiErrors.validation_error.forEach(error => {
          // Hidden fields error
          if (error.id === 'services_needed') hiddenFieldsError = true;
        });
      }
      errors.value = apiErrors;
      if (hiddenFieldsError) errors.value = {unexpected_error: 'Unexpected data corruption error'};
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const handleNavigation = (action: string) => {
  const progress = getUserSignupSavedProgress();
  if (progress && progress.user_two) {
    if (action === 'back')
      return router.push({
        name: CROSSGRID_PAGE_URLS.user.signup.step_two,
        params: {slug: progress.user_one},
      });
    isLoading.value = true;
    addJob(progress);
  }
};

// const userSignupForm = () => {
//   localStorage.setItem('user_signup_progress', JSON.stringify(formData));
//   router.push(CROSSGRID_PAGE_URLS.user.signup.step_four);
// };

const actionButtonDisabled = computed(() => {
  return Object.values(formData).length < 1 || Object.values(formData).some(value => value === '');
});

onMounted(async () => {
  userSession.value = await getSession();
  const progress = getUserSignupSavedProgress();
  if (!progress || !progress.user_two) return router.push(CROSSGRID_PAGE_URLS.user.signup.step_one);
  formData.job_description = progress.user_three?.job_description ?? '';
});
</script>
