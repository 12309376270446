<template>
  <CrossgridDashboardLayout sidebar-size="basis-1/3">
    <template #sidebar>
      <CrossgridSidebar :sidebar-items="PRO_SIDEBAR_ITEMS" />
    </template>
    <template #main>
      <main class="flex h-[calc(100vh-160px)] w-full">
        <CrossgridSidebarSecondary
          :primary-categories="primaryCategories"
          :secondary-categories="secondaryCategories" />
        <RouterView />
      </main>
    </template>
  </CrossgridDashboardLayout>
</template>

<script lang="ts" setup>
import {onMounted, reactive, watch} from 'vue';
import {CheckIcon, ClockIcon, InboxIcon, StarIcon, WrenchIcon} from '@heroicons/vue/24/solid';
import {RouterView, useRoute, useRouter} from 'vue-router';

import {PRO_SIDEBAR_ITEMS} from '@/constants/pro-profile';

import CrossgridDashboardLayout from '@/components/_layout/CrossgridDashboardLayout.vue';
import CrossgridSidebar from '@/components/organisms/CrossgridSidebar.vue';
import CrossgridSidebarSecondary from '@/components/organisms/CrossgridSidebarSecondary.vue';

const router = useRouter();

const primaryCategories = reactive([
  {name: 'Inbox', count: 0, icon: InboxIcon},
  {name: 'Starred', count: 0, icon: StarIcon},
  {name: 'Pending', count: 0, icon: ClockIcon},
  {name: 'Hired', count: 0, icon: WrenchIcon},
  {name: 'Job Completed', count: 0, icon: CheckIcon},
]);

const secondaryCategories = reactive([
  {name: 'Not Hired', count: 0},
  {name: 'Closed', count: 0},
]);

onMounted(() => {
  router.push({name: 'Inbox'});
});
</script>
