import {defineStore} from 'pinia';

import {MinimalUser} from '@/types/user';
import {useHttp} from '@/utils/composables';

const http = useHttp();

export const useUserStore = defineStore('user', {
  state: (): {user: MinimalUser | null} => ({
    user: null,
  }),
  actions: {
    async fetchUserSession(): Promise<void> {
      await http
        .get('/user/session/')
        .then(response => {
          this.user = response.data;
        })
        .catch(error => {
          console.log('session error', error.message);
          this.user = null;
        });
    },
  },
  getters: {
    getUser(state): MinimalUser | null {
      return state.user;
    },
  },
});
