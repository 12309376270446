<template>
  <CrossgridAuthLayout>
    <template #main>
      <CrossgridCard
        title="Slot used"
        description=""
        :signup-flow="true"
        :errors="errors"
        main-container-type="form"
        :action-button-disabled="actionButtonDisabled"
        :is-loading="isLoading"
        @cancel-click="handleNavigation('back')"
        @action-click="handleNavigation('next')">
        <template #title>Tell your customers<br />about yourself</template>
        <template #main>
          <div class="sm:col-span-3">
            <CrossgridLabel id="first_name" label="First name" />
            <CrossgridInput
              id="first_name"
              v-model="formData.first_name"
              :data-e2e="'first-name'"
              type="text"
              autocomplete="given-name"
              placeholder="Enter first name"
              :errors="errors" />
          </div>

          <div class="sm:col-span-3">
            <CrossgridLabel id="last_name" label="Last name" />
            <CrossgridInput
              id="last_name"
              v-model="formData.last_name"
              :data-e2e="'last-name'"
              type="text"
              autocomplete="family-name"
              placeholder="Enter last name"
              :errors="errors" />
          </div>

          <div class="sm:col-span-3">
            <CrossgridLabel id="name" label="Business name" />
            <CrossgridInput
              id="name"
              v-model="formData.name"
              :data-e2e="'business-name'"
              type="text"
              autocomplete="organization"
              placeholder="e.g. Crossgrid Inc."
              help-text="Use your official business name."
              :errors="errors" />
          </div>

          <div class="sm:col-span-3">
            <CrossgridLabel id="phone_number" label="Phone" />
            <CrossgridInput
              id="phone_number"
              v-model="formData.phone_number"
              :data-e2e="'phone-number'"
              type="text"
              autocomplete="tel"
              placeholder="Enter phone number"
              :errors="errors" />
          </div>

          <div class="sm:col-span-6">
            <CrossgridLabel id="address_1" label="Address" />
            <CrossgridAddressInput
              id="address_1"
              v-model="filledInAddress"
              :data-e2e="'address'"
              placeholder="Enter address"
              :errors="errors"
              @autocompleted-address="address => handleAutocompletedAddress(address)" />
            <p class="mt-1.5 text-xs text-text-tertiary dark:text-text-tertiary-dark">
              Used for verification purposes only. See our data privacy policy
              <a
                class="text-text-brand-primary"
                :href="CROSSGRID_PAGE_URLS.external.privacy_policy"
                target="_blank"
                >here.</a
              >
            </p>
          </div>

          <div v-if="addressComplete && formData.address.address_2" class="sm:col-span-6">
            <CrossgridLabel id="address_2" label="Apartment, building etc." />
            <CrossgridInput
              id="address_2"
              v-model="formData.address.address_2"
              :data-e2e="'address_2'"
              type="text"
              placeholder=""
              :disabled="true" />
          </div>

          <div v-if="addressComplete" class="sm:col-span-2">
            <CrossgridLabel id="locality_level_2" label="City" />
            <CrossgridInput
              id="locality_level_2"
              v-model="formData.address.locality_level_2"
              :data-e2e="'locality_level_2'"
              type="text"
              placeholder=""
              :disabled="true" />
          </div>

          <div v-if="addressComplete" class="sm:col-span-2">
            <CrossgridLabel id="locality_level_1" label="State" />
            <CrossgridInput
              id="locality_level_1"
              v-model="formData.address.locality_level_1"
              :data-e2e="'locality_level_1'"
              type="text"
              placeholder=""
              :disabled="true" />
          </div>

          <div v-if="addressComplete" class="sm:col-span-2">
            <CrossgridLabel id="postal_code" label="Zipcode" />
            <CrossgridInput
              id="postal_code"
              v-model="formData.address.postal_code"
              :data-e2e="'postal_code'"
              type="text"
              placeholder=""
              :disabled="true" />
          </div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridAuthLayout>
</template>

<script setup lang="ts">
import {computed, onBeforeMount, onMounted, reactive, Ref, ref, watch} from 'vue';
import {useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {AddressBase} from '@/types/common';
import {APIStandardError} from '@/types/network';
import {ProListingAddress} from '@/types/pro-listings';
import {ProSignupSavedProgress, ProSignupStepThree} from '@/types/pro-signup';
import {useHttp} from '@/utils/composables';
import {getProSignupSavedProgress} from '@/utils/pro-signup';

import CrossgridAuthLayout from '@/components/_layout/CrossgridAuthLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';
import CrossgridInput from '@/components/atoms/CrossgridInput.vue';
import CrossgridLabel from '@/components/atoms/CrossgridLabel.vue';
import CrossgridAddressInput from '@/components/molecules/CrossgridAddressInput.vue';

const router = useRouter();
const http = useHttp();

const formData = reactive<ProSignupStepThree>({} as ProSignupStepThree);
const errors: Ref<APIStandardError | undefined> = ref(undefined);
const filledInAddress = ref('');
const addressComplete = ref(false);
const isLoading = ref(false);

const validateForm = (progress: ProSignupSavedProgress) => {
  http
    .post('/pro/pro_signup/validate_create_account_pre_form/', formData)
    .then(() => {
      progress.pro_three = formData;
      localStorage.setItem('pro_signup_progress', JSON.stringify(progress));
      return router.push(CROSSGRID_PAGE_URLS.pro.signup.step_four);
    })
    .catch(error => {
      const apiErrors: APIStandardError = error.response?.data;
      let addressError = false;
      let hiddenFieldsError = false;

      if (apiErrors.validation_error) {
        apiErrors.validation_error.forEach(error => {
          // Hidden fields error
          if (error.id === 'services_offered') hiddenFieldsError = true;
          // Check if any of the address keys are present, so we can send a different error message
          if (Object.keys(formData.address).includes(error.id)) addressError = true;
        });
      }
      errors.value = apiErrors;
      if (addressError) errors.value = {unexpected_error: 'Unexpected address information error'};
      if (hiddenFieldsError) errors.value = {unexpected_error: 'Unexpected data corruption error'};
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const handleNavigation = (action: string) => {
  const progress = getProSignupSavedProgress();
  if (progress && progress.pro_two) {
    if (action === 'back')
      return router.push({
        name: CROSSGRID_PAGE_URLS.pro.signup.step_two,
        params: {slug: progress.pro_one.slug},
      });
    isLoading.value = true;
    validateForm(progress);
  }
};

const handleAutocompletedAddress = (address: AddressBase) => {
  const proListingAddress: ProListingAddress = {...address, visitor_address: false};
  formData.address = proListingAddress;
  addressComplete.value = true;
};

const actionButtonDisabled = computed(() => {
  return Object.values(formData).length < 5 || Object.values(formData).some(value => value === '');
});

watch(filledInAddress, async () => {
  addressComplete.value = filledInAddress.value === formData.address?.address_1 ? true : false;
});

onMounted(() => {
  const progress = getProSignupSavedProgress();
  if (progress?.pro_two) {
    formData.services_offered = progress.pro_two;
  }
  if (progress?.pro_three) {
    Object.assign(formData, progress.pro_three);
    filledInAddress.value = progress.pro_three.address.address_1 || '';
  }
});

onBeforeMount(() => {
  // Make sure that step 2 is done, otherwise redirect user back
  const progress = getProSignupSavedProgress();
  if (!progress || !progress.pro_two || !progress.pro_one)
    return router.push(CROSSGRID_PAGE_URLS.pro.signup.step_one);
});
</script>
