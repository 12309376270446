<template>
  <CrossgridAuthLayout>
    <template #main>
      <CrossgridCard
        title="Tell us about yourself"
        description=""
        :signup-flow="true"
        :errors="errors"
        :action-button-disabled="actionButtonDisabled"
        :is-loading="isLoading"
        @cancel-click="handleNavigation('back')"
        @action-click="handleNavigation('next')">
        <template #main>
          <div class="sm:col-span-3">
            <CrossgridLabel id="first_name" label="First name" />
            <CrossgridInput
              id="first_name"
              v-model="formData.first_name"
              type="text"
              autocomplete="given-name"
              placeholder="Enter first name"
              :errors="errors" />
          </div>

          <div class="sm:col-span-3">
            <CrossgridLabel id="last_name" label="Last name" />
            <CrossgridInput
              id="last_name"
              v-model="formData.last_name"
              type="text"
              autocomplete="family-name"
              placeholder="Enter last name"
              :errors="errors" />
          </div>

          <div class="sm:col-span-3">
            <CrossgridLabel id="phone_number" label="Phone" />
            <CrossgridInput
              id="phone_number"
              v-model="formData.phone_number"
              type="text"
              autocomplete="tel"
              placeholder="Enter phone number"
              :errors="errors" />
          </div>

          <div class="sm:col-span-3">
            <CrossgridLabel id="zipcode" label="Zipcode" />
            <CrossgridInput
              id="zipcode"
              v-model="formData.zipcode"
              type="text"
              autocomplete="postal-code"
              placeholder="Enter zipcode"
              :errors="errors" />
          </div>
          <!-- Add to form data -->
          <div class="sm:col-span-6">
            <CrossgridLabel id="job_description" label="Job Description" />
            <CrossgridInput
              id="job_description"
              v-model="formData.job_description"
              type="multiline"
              placeholder="Enter description of the job you need done"
              :errors="errors" />
          </div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridAuthLayout>
</template>

<script setup lang="ts">
import {computed, onBeforeMount, onMounted, reactive, ref, Ref} from 'vue';
import {useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {APIStandardError} from '@/types/network';
import {UserSignupSavedProgress, UserSignupStepThree} from '@/types/user-signup';
import {useHttp} from '@/utils/composables';
import {getUserSignupSavedProgress} from '@/utils/user-signup';

import CrossgridAuthLayout from '@/components/_layout/CrossgridAuthLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';
import CrossgridInput from '@/components/atoms/CrossgridInput.vue';
import CrossgridLabel from '@/components/atoms/CrossgridLabel.vue';

const router = useRouter();
const http = useHttp();

const formData = reactive<UserSignupStepThree>({} as UserSignupStepThree);
const errors: Ref<APIStandardError | undefined> = ref(undefined);
const isLoading = ref(false);

const validateForm = (progress: UserSignupSavedProgress) => {
  http
    .post('/user/user_signup/validate_create_account_pre_form/', formData)
    .then(() => {
      progress.user_three = formData;
      localStorage.setItem('user_signup_progress', JSON.stringify(progress));
      return router.push(CROSSGRID_PAGE_URLS.user.signup.step_four);
    })
    .catch(error => {
      const apiErrors: APIStandardError = error.response?.data;
      let hiddenFieldsError = false;

      if (apiErrors.validation_error) {
        apiErrors.validation_error.forEach(error => {
          // Hidden fields error
          if (error.id === 'services_needed') hiddenFieldsError = true;
        });
      }
      errors.value = apiErrors;
      if (hiddenFieldsError) errors.value = {unexpected_error: 'Unexpected data corruption error'};
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const handleNavigation = (action: string) => {
  const progress = getUserSignupSavedProgress();
  if (progress && progress.user_two) {
    if (action === 'back')
      return router.push({
        name: CROSSGRID_PAGE_URLS.user.signup.step_two,
        params: {slug: progress.user_one},
      });
    isLoading.value = true;
    validateForm(progress);
  }
};

// const userSignupForm = () => {
//   localStorage.setItem('user_signup_progress', JSON.stringify(formData));
//   router.push(CROSSGRID_PAGE_URLS.user.signup.step_four);
// };

const actionButtonDisabled = computed(() => {
  return Object.values(formData).length < 4 || Object.values(formData).some(value => value === '');
});

onMounted(() => {
  const progress = getUserSignupSavedProgress();
  if (progress?.user_two) {
    formData.services_needed = progress.user_two;
  }
  if (progress?.user_three) {
    Object.assign(formData, progress.user_three);
  }
});

onBeforeMount(() => {
  // Make sure that step 2 is done, otherwise redirect user back
  const progress = getUserSignupSavedProgress();
  if (!progress || !progress.user_one || !progress.user_two)
    return router.push(CROSSGRID_PAGE_URLS.user.signup.step_one);
});

// onMounted(() => {
//   const progress = localStorage.getItem('user_signup_progress');
//   if (progress) {
//     const parsedProgress = JSON.parse(progress);
//     formData.first_name = parsedProgress.first_name;
//     formData.last_name = parsedProgress.last_name;
//     formData.phone_number = parsedProgress.phone_number;
//     formData.zipcode = parsedProgress.zipcode;
//     formData.job_description = parsedProgress.job_description;
//   }
// });
</script>
