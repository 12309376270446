<template>
  <RouterView />
</template>

<script setup lang="ts">
import {onMounted} from 'vue';

import {useUserStore} from './stores/userStore';

onMounted(() => {
  // Fetch user data when app starts
  const userStore = useUserStore();
  userStore.fetchUserSession();
});
</script>
