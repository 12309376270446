<template>
  <nav aria-label="Dashboard">
    <ul role="list" class="flex justify-evenly lg:flex-col">
      <li v-for="item in sidebarItems" :key="item.displayName">
        <RouterLink
          :to="{name: item.routeName}"
          :class="[
            route.name === item.routeName
              ? 'bg-bg-tertiary text-text-primary dark:bg-bg-secondary-dark dark:text-text-primary-dark'
              : 'hover:text-text- hover:bg-bg-secondary dark:text-text-secondary-dark dark:hover:bg-bg-tertiary-dark dark:hover:text-text-primary-dark',
            'mt-2 flex items-center space-x-3 rounded-full p-4 text-sm leading-6 transition-colors duration-75 md:rounded-3xl md:px-6 md:py-3 lg:my-2 lg:ml-[23px] lg:mr-0 lg:mt-0 lg:rounded-r-none lg:pl-5 lg:pr-32',
          ]">
          <component :is="item.icon" class="h-5 w-5 shrink-0" aria-hidden="true" />

          <div class="hidden md:block">{{ item.displayName }}</div>
        </RouterLink>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import {RouterLink, useRoute} from 'vue-router';

import {SidebarItems} from '@/types/ui-elements';

type Props = {
  sidebarItems: SidebarItems[];
};

defineProps<Props>();

const route = useRoute();
</script>
