import {ref} from 'vue';

import {ProBusinessHours} from '@/types/pro-profile';
import {useHttp} from '@/utils/composables';

const http = useHttp();

export const generatedTimes = Array.from({length: 96}, (_, i) => {
  const hour = Math.floor(i / 4);
  const minute = (i % 4) * 15;
  const ampm = hour >= 12 ? 'PM' : 'AM';
  const hours12 = hour % 12 || 12;
  return `${hours12.toString()}:${minute.toString().padStart(2, '0')} ${ampm}`;
});

export const convertTo24HourTime = (time: string) => {
  const [hoursMinutes, ampm] = time.split(' ');
  const hm = hoursMinutes.split(':');
  let hours = hm[0];
  const minutes = hm[1];

  if (hours === '12') {
    hours = '00';
  }

  if (ampm === 'PM') {
    hours = (parseInt(hours, 10) + 12).toString();
  }

  return `${hours.padStart(2, '0')}:${minutes}`;
};

export const convertTo12HourTime = (time: string) => {
  const [hours, minutes] = time.split(':');
  const hour = parseInt(hours, 10);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  const hours12 = hour % 12 || 12;
  return `${hours12.toString()}:${minutes} ${ampm}`;
};

export const isSaved = ref(true);
export const isLoading = ref(false);

export const proAvailability = ref<ProBusinessHours[]>([]);
export const addedProAvailability = ref<ProBusinessHours[]>([]);
export const updatedProAvailability = ref<ProBusinessHours[]>([]);
export const deletedProAvailability = ref<ProBusinessHours[]>([]);

export const getProAvailability = async (slug: string | null): Promise<ProBusinessHours[]> => {
  const availability = await http.get(`/pro/pro_listing/${slug}/availability/`);

  const formattedResponse = availability.data.flatMap((day: ProBusinessHours) => [
    {
      from_hour: day.from_hour.split(':').slice(0, 2).join(':'),
      to_hour: day.to_hour.split(':').slice(0, 2).join(':'),
      weekday: day.weekday,
      pro_listing: slug,
      slug: day.slug,
    },
  ]);

  return formattedResponse;
};

export const setProAvailability = async (proListing: string) => {
  isLoading.value = true;
  proAvailability.value = await getProAvailability(proListing);
  isLoading.value = false;
};
