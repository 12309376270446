<template>
  <button
    :type="type"
    :disabled="actionButtonDisabled"
    :data-e2e="'crossgrid-button'"
    class="text-md flex w-full items-center justify-center gap-2 rounded-md bg-button-primary-bg px-4 py-2.5 font-semibold text-button-primary-fg shadow-sm ring-1 ring-inset ring-button-primary-border hover:bg-button-primary-bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-button-primary-border disabled:cursor-not-allowed disabled:bg-bg-disabled disabled:text-text-disabled disabled:ring-border-disabled-subtle dark:disabled:bg-bg-disabled-dark dark:disabled:text-text-disabled-dark dark:disabled:ring-border-disabled-dark-subtle"
    @click="(e: Event) => emits('action-click', e)">
    <component class="h-5 w-5" :is="icon" aria-hidden="true" />
    {{ label }}
  </button>
</template>

<script lang="ts" setup>
import {FunctionalComponent} from 'vue';

import {APIStandardError} from '@/types/network';

type Props = {
  icon?: FunctionalComponent;
  errors?: APIStandardError;
  label?: string | null;
  actionButtonDisabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
};

defineProps<Props>();

const emits = defineEmits<{
  (e: 'action-click', value: Event): Event;
}>();
</script>
