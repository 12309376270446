// import {useHttp} from '@/utils/composables';

import {watch} from 'vue';
import {useUserStore} from '@/stores/userStore';

import {MinimalUser} from '@/types/user';

// export const getSession = () => {
//   const http = useHttp();
//   http
//     .get('/user/session/')
//     .then(response => {
//       localStorage.setItem('userSession', JSON.stringify(response.data));
//       if (response.status == 200) location.reload();
//     })
//     .catch(error => console.log('session error', error.message));
// };

export async function getSession(): Promise<MinimalUser | null> {
  const userStore = useUserStore();

  return new Promise<MinimalUser | null>(resolve => {
    if (userStore.user) {
      resolve(userStore.user);
    } else {
      const unwatch = watch(
        () => userStore.user,
        user => {
          if (user) {
            unwatch();
            resolve(user);
          }
        }
      );
    }
  });
}
