<template>
  <div class="w-full">
    <Listbox v-model="selectedValue" :disabled="disabled">
      <div class="relative">
        <ListboxButton
          class="bg-primary focus:ring-border-brand/2 relative h-12 w-full cursor-pointer rounded-lg border-2 py-2 pl-3 pr-10 text-left focus:outline-none focus:ring-2 dark:border-border-primary-dark dark:bg-bg-primary-dark dark:text-text-primary-dark dark:focus:ring-border-brand-dark/25 sm:text-sm">
          <span class="block truncate">{{ selectedValue }}</span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0">
          <ListboxOptions
            class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-left text-base text-text-primary shadow-lg ring-1 ring-black/5 focus:outline-none dark:bg-bg-primary-dark dark:text-text-primary-dark sm:text-sm">
            <ListboxOption
              v-for="option in options"
              v-slot="{selected}"
              :key="option"
              :value="option">
              <li
                class="relative cursor-pointer select-none p-2 text-text-primary hover:bg-button-primary-bg-hover hover:text-white dark:text-text-primary-dark">
                <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">{{
                  option
                }}</span>
                <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-3">
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script setup lang="ts">
import {ref, watch} from 'vue';
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/vue';
import {CheckIcon, ChevronDownIcon} from '@heroicons/vue/20/solid';

interface Props {
  modelValue: string;
  options: string[];
  disabled?: boolean;
}

const emits = defineEmits(['update:modelValue']);

const props = defineProps<Props>();

const selectedValue = ref(props.modelValue);

watch(selectedValue, newValue => {
  emits('update:modelValue', newValue);
});

watch(
  () => props.modelValue,
  newValue => {
    selectedValue.value = newValue;
  }
);
</script>
