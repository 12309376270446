<template>
  <SwitchGroup as="div" class="flex items-center">
    <Switch
      v-model="modelValue"
      :disabled="disabled"
      :class="[
        modelValue ? 'bg-bg-brand-solid' : 'bg-bg-quaternary dark:bg-bg-quaternary-dark',
        'focus:ring-border-brand/2 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 dark:focus:ring-border-brand-dark/25',
      ]">
      <span
        aria-hidden="true"
        :class="[
          modelValue ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        ]" />
    </Switch>
    <SwitchLabel v-if="label" as="span" class="pl-3 text-sm">
      <div v-if="darkModeSwitch" class="flex items-center gap-1">
        <MoonIcon class="h-5 w-5 text-yellow-300"></MoonIcon>
        <span class="font-semibold text-text-primary dark:text-text-primary-dark">{{ label }}</span>
      </div>
      <span v-else class="font-medium text-text-primary dark:text-text-primary-dark">{{
        label
      }}</span>
    </SwitchLabel>
  </SwitchGroup>
</template>

<script setup lang="ts">
import {computed, onMounted} from 'vue';
import {Switch, SwitchGroup, SwitchLabel} from '@headlessui/vue';
import {MoonIcon} from '@heroicons/vue/20/solid';

type Props = {
  modelValue: boolean;
  label?: string;
  darkModeSwitch?: boolean;
  disabled?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  label: undefined,
  modelValue: false,
  darkModeSwitch: false,
  disabled: false,
});

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

onMounted(() => {
  emits('update:modelValue', props.modelValue);
});

const modelValue = computed({
  get: () => props.modelValue,
  set: value => {
    emits('update:modelValue', value);
  },
});
</script>
