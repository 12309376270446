<template>
  <CrossgridHeader :contained-width="true" />
  <section class="relative">
    <div class="h-[500px] md:h-[700px] lg:flex lg:justify-center">
      <div
        :style="bgImage(`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/4.jpg`)"
        class="absolute top-0 h-full w-full bg-cover bg-center bg-no-repeat md:hidden"></div>
      <div
        :style="bgImage(`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/crossgrid-icon.svg`)"
        class="absolute top-0 hidden h-full w-full bg-[length:45px_42px] bg-repeat md:block"></div>
      <div
        class="absolute top-0 h-full w-full bg-bg-secondary bg-opacity-[85%] dark:bg-bg-secondary-dark dark:bg-opacity-[97%] md:relative md:bg-opacity-[97%]"></div>
      <div class="top-0 w-full max-w-5xl lg:absolute">
        <div class="hidden md:block lg:absolute lg:left-5 lg:top-10">
          <div
            :style="bgImage(`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/1.jpg`)"
            class="absolute left-0 top-0 hidden bg-cover bg-center shadow lg:block lg:h-[348px] lg:w-[233px] lg:rounded-[28px]"></div>
          <div
            :style="bgImage(`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/2.jpg`)"
            class="absolute left-[200px] top-[41px] hidden bg-cover bg-center shadow lg:left-[246px] lg:top-[71px] lg:block lg:h-[348px] lg:w-[232px] lg:rounded-[28px]"></div>
          <div
            :style="bgImage(`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/3.jpg`)"
            class="absolute top-0 h-full w-1/2 bg-cover bg-center shadow lg:left-[65px] lg:top-[211px] lg:h-[348px] lg:w-[233px] lg:rounded-[28px]"></div>
        </div>
        <div
          class="relative flex h-[500px] w-full flex-col justify-center gap-3 whitespace-nowrap md:absolute md:right-0 md:top-0 md:h-full md:w-7/12 lg:top-20 lg:block lg:w-7/12">
          <h1
            class="mr-5 mt-20 text-right font-['Inter'] text-5xl font-medium text-text-primary dark:text-text-primary-dark md:mt-0 lg:text-6xl">
            <div class="mb-5">we connect</div>
            <div class="mb-5">customers with</div>
            <div>
              <span
                class="bg-bg-brand-primary-dark pl-10 pr-4 font-bold text-text-primary-on-brand lg:pl-28"
                >value based</span
              >
              <br />
              <div class="mt-5">businesses</div>
            </div>
          </h1>

          <div
            class="mt-10 space-y-4 bg-bg-primary px-8 py-8 dark:border-border-primary-dark dark:bg-bg-secondary-dark dark:text-text-primary-dark md:mr-5 md:rounded-2xl md:border-2 md:px-10">
            <div class="flex w-full md:w-[300px]">
              <button
                type="button"
                :class="[
                  searchType === 'user'
                    ? 'border-b border-border-brand font-bold'
                    : 'border-b border-border-primary text-text-tertiary dark:border-border-tertiary-dark dark:text-text-primary-dark',
                  'w-full',
                ]"
                @click="handleSelectSearchType('user')">
                Find a Pro
              </button>
              <button
                type="button"
                :class="[
                  searchType === 'pro'
                    ? 'border-b border-border-brand font-bold'
                    : 'border-b border-border-primary text-text-tertiary dark:border-border-tertiary-dark dark:text-text-primary-dark',
                  'w-full',
                ]"
                @click="
                  !userSession?.is_authenticated &&
                    !userSession?.pro_listing &&
                    handleSelectSearchType('pro')
                ">
                Find Customers
              </button>
            </div>
            <div>
              <form
                class="flex flex-col gap-2 sm:flex-row"
                @submit.prevent="handleSubmitSearch(searchType)">
                <div class="flex-1">
                  <CrossgridSearch
                    v-model="selectedService"
                    :proServices="proServices"
                    @search-query="(query: string) => handleSearchQuery(query)"
                    :placeholder="
                      searchType === 'pro'
                        ? 'What services do you provide?'
                        : 'What services are you looking for?'
                    " />
                </div>

                <div class="hidden sm:flex sm:items-center">
                  <CrossgridIconButton
                    :icon="MagnifyingGlassIcon"
                    type="submit"
                    name="search"
                    size="md" />
                </div>
                <div class="pt-2 sm:hidden">
                  <CrossgridButton :icon="MagnifyingGlassIcon" label="Search" type="submit" />
                </div>
              </form>
              <p
                class="mt-2 text-xs text-text-tertiary dark:text-text-tertiary-dark"
                id="business-description">
                Try searching Electrician House Cleaning, or Painter
              </p>
            </div>
          </div>
        </div>
      </div>

      <button
        class="absolute hidden md:bottom-8 md:left-[calc(75%-20px)] md:block lg:left-[calc(50%-20px)]"
        @click="scrollToSection('service')">
        <ArrowDownIcon
          class="arrow-down h-10 w-10 text-text-secondary dark:text-text-secondary-dark" />
      </button>
    </div>
  </section>
  <section id="service">
    <div class="my-20 py-10">
      <h2
        class="px-10 pb-10 text-center text-5xl font-medium text-text-primary dark:text-text-primary-dark">
        Experience <span class="text-brand-700">excellent</span> service
      </h2>
      <div
        class="flex max-w-7xl flex-col items-center justify-center gap-12 px-5 md:flex-row lg:mx-auto">
        <div class="relative w-full md:w-1/2 lg:w-[450px]">
          <div
            :style="bgImage(`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/service.jpg`)"
            class="h-[450px] rounded-[28px] bg-cover bg-center"></div>
        </div>
        <div class="w-full md:w-1/2 lg:w-[450px]">
          <h3 class="text-3xl font-light text-text-primary dark:text-text-primary-dark">
            What makes Crossgrid different?
          </h3>
          <h4 class="mt-2 text-xl font-light text-text-tertiary dark:text-text-tertiary-dark">
            We vet our pros to ensure every customer has a great experience
          </h4>
          <ul class="mt-10 space-y-5 font-medium text-text-primary dark:text-text-primary-dark">
            <li class="flex items-center gap-2">
              <CheckIcon class="h-5 w-5 text-brand-700" aria-hidden="true" />
              <p>Dedicated teams that are faith-based</p>
            </li>
            <li class="flex items-center gap-2">
              <CheckIcon class="h-5 w-5 text-brand-700" />
              <p>Strict vetting process for new pro listings</p>
            </li>
            <li class="flex items-center gap-2">
              <CheckIcon class="h-5 w-5 text-brand-700" />
              <p>Strong emphasis on customer satisfaction</p>
            </li>
            <li class="flex items-center gap-2">
              <CheckIcon class="h-5 w-5 text-brand-700" />
              <p>Excellent experience throughout transaction</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="relative">
    <div
      :style="bgImage(`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/crossgrid-icon.svg`)"
      class="absolute top-0 h-[500px] w-full bg-[length:45px_42px]"></div>
    <div class="relative h-[500px] w-full bg-brand-700 bg-opacity-[97%]"></div>
    <div
      class="absolute top-0 flex h-full w-full flex-col items-center justify-center space-y-8 px-5 text-center font-light text-white">
      <h2 class="max-w-[800px] text-5xl font-bold text-white">Ready to start your project?</h2>
      <h3 class="max-w-[600px] text-2xl">
        Get matched with businesses or customers today and get your project going.
      </h3>
      <div class="flex gap-4 pt-12">
        <RouterLink
          :to="CROSSGRID_PAGE_URLS.user.signup.step_one"
          class="text-md rounded-lg bg-bg-primary px-5 py-2 font-medium text-text-brand-primary hover:bg-bg-tertiary-hover">
          Find a Pro
        </RouterLink>
        <RouterLink
          :to="CROSSGRID_PAGE_URLS.pro.signup.step_one"
          class="text-md rounded-lg border-2 border-white px-5 py-2 font-medium text-white hover:bg-brand-500">
          Find Customers
        </RouterLink>
      </div>
    </div>
  </section>
  <section id="pricing" class="relative">
    <div class="mx-auto max-w-5xl pt-10">
      <div
        class="mx-auto flex max-w-5xl flex-col px-10 pb-5 text-text-primary dark:text-text-primary-dark">
        <h2 class="mt-12 text-3xl font-bold md:text-4xl">How it works</h2>
        <h3 class="mt-3 text-xl font-light text-text-secondary dark:text-text-secondary-dark">
          For Customers:
        </h3>
      </div>

      <div
        class="mx-auto flex max-w-5xl flex-col justify-between text-center text-xl font-light text-text-secondary dark:text-text-secondary-dark lg:flex-row">
        <div
          class="flex flex-col items-center gap-3 p-12 md:flex-row lg:w-1/3 lg:shrink-0 lg:flex-col">
          <img
            :src="`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/marketing/browse.svg`"
            class="mx-auto shrink-0 sm:h-72 sm:w-72" />
          <p class="mx-auto max-w-sm py-10">
            Search our wide range of services that meet your need
          </p>
        </div>
        <div
          class="flex flex-col items-center gap-3 p-12 md:flex-row-reverse lg:w-1/3 lg:shrink-0 lg:flex-col">
          <img
            :src="`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/marketing/referral.svg`"
            class="mx-auto shrink-0 sm:h-72 sm:w-72" />
          <p class="mx-auto max-w-sm py-10">
            Get matched with faith-based professionals in your area
          </p>
        </div>
        <div
          class="flex flex-col items-center gap-3 p-12 md:flex-row lg:w-1/3 lg:shrink-0 lg:flex-col">
          <img
            :src="`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/marketing/complete.svg`"
            class="mx-auto shrink-0 sm:h-72 sm:w-72" />
          <p class="mx-auto max-w-sm py-10">No extra work required, 100% free for customers</p>
        </div>
      </div>
      <div class="flex justify-center gap-4">
        <RouterLink
          :to="CROSSGRID_PAGE_URLS.user.signup.step_one"
          class="text-md rounded-lg bg-brand-700 px-5 py-2 font-medium text-white hover:bg-brand-800">
          Find a Pro
        </RouterLink>
      </div>
    </div>
    <div class="pt-10">
      <div
        class="mx-auto flex max-w-5xl flex-col px-10 pb-5 text-text-primary dark:text-text-primary-dark">
        <h3 class="mt-3 text-xl font-light text-text-secondary dark:text-text-secondary-dark">
          For Pros:
        </h3>
      </div>
      <div
        class="mx-auto flex max-w-5xl flex-col justify-between text-center text-xl font-light text-text-secondary dark:text-text-secondary-dark lg:flex-row">
        <div
          class="flex flex-col items-center gap-3 p-12 md:flex-row lg:w-1/3 lg:shrink-0 lg:flex-col">
          <img
            :src="`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/marketing/search.svg`"
            class="mx-auto shrink-0 sm:h-72 sm:w-72" />
          <p class="mx-auto max-w-sm py-10">
            Customers come to the platform looking for your services
          </p>
        </div>
        <div
          class="flex flex-col items-center gap-3 p-12 md:flex-row-reverse lg:w-1/3 lg:shrink-0 lg:flex-col">
          <img
            :src="`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/marketing/analysis.svg`"
            class="mx-auto shrink-0 sm:h-72 sm:w-72" />
          <p class="mx-auto max-w-sm py-10">
            We ensure you’re a good fit for the job and the customer is serious
          </p>
        </div>
        <div
          class="flex flex-col items-center gap-3 p-12 md:flex-row lg:w-1/3 lg:shrink-0 lg:flex-col">
          <img
            :src="`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/marketing/success.svg`"
            class="mx-auto shrink-0 sm:h-72 sm:w-72" />
          <p class="mx-auto max-w-sm py-10">
            You pay once we determine it’s a quality lead and you’re a good fit for the job
          </p>
        </div>
      </div>
      <div class="flex justify-center gap-4">
        <RouterLink
          :to="CROSSGRID_PAGE_URLS.user.signup.step_one"
          class="text-md rounded-lg bg-brand-700 px-5 py-2 font-medium text-white hover:bg-brand-800">
          Find Customers
        </RouterLink>
      </div>
    </div>
  </section>
  <section class="relative">
    <div class="mx-auto flex max-w-4xl flex-col items-center gap-5 py-16 md:flex-row md:px-5">
      <div
        :style="bgImage(`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/fam.jpg`)"
        class="h-[448px] w-full bg-cover bg-center shadow md:w-1/2 md:rounded-[28px]"></div>

      <div class="w-full max-w-xl space-y-8 px-5 md:w-1/2">
        <h3
          class="mt-10 text-4xl font-medium text-text-primary dark:text-text-primary-dark md:mt-0">
          “Crossgrid got me connected with a great plumber who was very knowledgeable and was great
          to work with”
        </h3>
        <p class="font-light text-text-secondary dark:text-text-secondary-dark">
          Jack Smith, Long Island NY
        </p>
        <div class="flex gap-4 pt-12">
          <RouterLink
            :to="CROSSGRID_PAGE_URLS.user.signup.step_one"
            class="text-md rounded-lg bg-brand-700 px-5 py-2 font-medium text-white hover:bg-brand-800">
            Find a Pro
          </RouterLink>
          <RouterLink
            :to="CROSSGRID_PAGE_URLS.pro.signup.step_one"
            class="text-md rounded-lg border-2 border-border-secondary bg-bg-primary px-5 py-2 font-medium text-text-primary hover:bg-bg-secondary dark:border-border-primary-dark dark:bg-bg-primary-dark dark:text-text-primary-dark">
            Find Customers
          </RouterLink>
        </div>
      </div>
    </div>
  </section>
  <section id="about">
    <div class="mx-auto px-5 pb-16 text-text-primary dark:text-text-primary-dark">
      <div
        class="flex items-center justify-center space-x-4 text-4xl font-medium text-text-primary dark:text-text-primary-dark md:text-5xl">
        <img src="/assets/images/crossgrid-icon.svg" class="h-10 w-10" />
        <h2 class="py-10">About Crossgrid</h2>
      </div>
      <div class="md:px-5 lg:px-10">
        <div class="flex items-center justify-center gap-2">
          <div class="flex max-w-sm flex-col items-center space-y-5 py-10 md:w-1/2 md:pr-10">
            <h3 class="w-full text-center text-2xl font-medium md:text-left">
              Bridging Faith and Business
            </h3>
            <p class="w-full md:text-sm">
              Welcome to Crossgrid, where we connect communities through faith and service. Our app
              is designed to support the Christian community by linking customers with the
              professional services they are looking for, be it plumbing or piano lessons. While our
              focus is on the Christian community, we believe that Jesus’ love covers every corner
              of this earth, and we welcome ALL users on our platform.
            </p>
            <h3 class="w-full text-center text-2xl font-medium md:text-left">Our Story</h3>
            <p class="w-full md:text-sm">
              Our journey began with a simple observation: there is a significant disconnect between
              Christians and business. Many of us attend church every Sunday, sitting next to people
              whose professional skills and services remain unknown to us. In our broader
              communities, finding and supporting fellow Christians in business is often a
              challenge.
            </p>
          </div>

          <div class="hidden md:block">
            <div
              :style="bgImage(`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/about.jpg`)"
              class="h-[448px] w-[333px] rounded-[28px] bg-cover bg-center shadow"></div>
          </div>
        </div>
        <div class="mx-auto max-w-sm space-y-5 md:max-w-3xl md:px-2 md:px-7">
          <h3 class="w-full text-center text-2xl font-medium md:text-left">Our Mission</h3>
          <p class="w-full md:text-sm">
            We aim to close the gap between faith and business by creating the largest network of
            Christian business owners and bringing them the customers they seek. Our mission is
            twofold:
          </p>
          <ol class="list-decimal px-5 md:text-sm">
            <li>
              <span class="font-bold">Support and Growth:</span> By fostering connections within the
              Christian community, we help believers support each other professionally and
              personally.
            </li>
            <li>
              <span class="font-bold">Evangelism Through Excellence:</span> Through the quality and
              integrity of the services provided by our Pros, we hope to make a positive impact on
              the non-Christian community. We believe that exceptional service grounded in faith can
              serve as a powerful form of evangelism.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <CrossgridFooter :contained-width="true" />
</template>
<script setup lang="ts">
import {onMounted, ref, Ref} from 'vue';
import {ArrowDownIcon, CheckIcon} from '@heroicons/vue/20/solid';
import {MagnifyingGlassIcon} from '@heroicons/vue/24/solid';
import {RouterLink, useRouter} from 'vue-router';

import {CROSSGRID_IMAGE_HOSTING_BASE_URL, CROSSGRID_PAGE_URLS} from '@/constants/common';
import {ProService} from '@/types/pro-services';
import {ProSignupSavedProgress} from '@/types/pro-signup';
import {MinimalUser} from '@/types/user';
import {UserSignupSavedProgress} from '@/types/user-signup';
import {searchProService} from '@/utils/pro-services-search';
import {getSession} from '@/utils/shared';

import CrossgridButton from '@/components/atoms/CrossgridButton.vue';
import CrossgridIconButton from '@/components/atoms/CrossgridIconButton.vue';
import CrossgridSearch from '@/components/molecules/CrossgridSearch.vue';
import CrossgridFooter from '@/components/partials/CrossgridFooter.vue';
import CrossgridHeader from '@/components/partials/CrossgridHeader.vue';

const selectedService: Ref<ProService | null> = ref(null);
const proServices: Ref<ProService[]> = ref([]);
const searchType: Ref<string> = ref('user');

// const userSession: UserSession = JSON.parse(
//   localStorage.getItem('userSession') || '{}'
// ) as UserSession;

const userSession: Ref<MinimalUser | null> = ref(null);

const router = useRouter();

const handleSearchQuery = async (searchQuery: string) => {
  if (searchQuery === '') selectedService.value = null;
  proServices.value = await searchProService(searchQuery);
};

const bgImage = (url: string, size?: string) => {
  if (size === 'sm') {
    return `@media (min-width: 640px) {
      background-image: url(${url});
    }`;
  } else if (size === 'md') {
    return `@media (min-width: 768px) {
      background-image: url(${url});
    }`;
  } else {
    return `background-image: url(${url})`;
  }
};

const handleSubmitSearch = (entityType: string) => {
  if (selectedService.value) {
    if (entityType === 'user') {
      const progress: UserSignupSavedProgress = {user_one: selectedService.value.slug as string};
      localStorage.setItem('user_signup_progress', JSON.stringify(progress));
      router.push({
        name: CROSSGRID_PAGE_URLS.user.signup.step_two,
        params: {slug: selectedService.value.slug},
      });
    } else {
      const progress: ProSignupSavedProgress = {pro_one: selectedService.value};
      localStorage.setItem('pro_signup_progress', JSON.stringify(progress));
      router.push({
        name: CROSSGRID_PAGE_URLS.pro.signup.step_two,
        params: {slug: selectedService.value.slug},
      });
    }
  }
};

const scrollToSection = (section: string) => {
  const element = document.getElementById(section);
  if (element) {
    element.scrollIntoView({behavior: 'smooth'});
  }
};

const handleSelectSearchType = (type: string) => {
  searchType.value = type;
};

onMounted(async () => {
  userSession.value = await getSession();
});
</script>

<style>
* {
  scroll-behavior: smooth;
}
</style>
