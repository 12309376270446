<template>
  <CrossgridDashboardLayout sidebar-size="basis-1/3">
    <template #sidebar>
      <DashboardSidebar :sidebar-items="PRO_SIDEBAR_ITEMS" />
    </template>
    <template #main>
      <div
        class="mx-auto max-w-xl items-center py-12 text-center font-light text-text-primary dark:text-text-primary-dark">
        <div
          class="flex flex-col gap-5 rounded-2xl bg-bg-primary p-12 shadow dark:bg-bg-primary-dark">
          <h2 class="text-xl font-bold">🎉 Welcome to Crossgrid!</h2>
          <p class="text-sm">
            Thank you for joining our community. We're thrilled to have you on board as we continue
            to build the tools and features you need to grow your business.
          </p>
          <h2 class="text-lg font-bold">🏗️ Pardon Our Progress</h2>
          <p class="text-sm">
            Please excuse our “Coming Soon” sections as we work diligently to enhance our platform.
            Our goal is to create solutions that truly serve your business and to do that, we
            believe in collaborating directly with you to understand your needs.
          </p>
          <h2 class="text-lg font-bold">✉️ What to Expect</h2>
          <p class="text-sm">
            At Crossgrid, we're committed to providing the best support possible for your business.
            To ensure you receive the personalized attention you deserve, one of our Success
            Specialists will be assigned to your account. Shortly after signing up, you’ll hear from
            them—they’ll take the time to get to know your business and the services you offer.
            Their primary focus will be connecting you with qualified customers who need those
            services.
          </p>
          <p class="text-sm">
            We look forward to working with you and becoming a trusted partner in the growth and
            success of your business.
          </p>
        </div>
      </div>
    </template>
  </CrossgridDashboardLayout>
</template>

<script lang="ts" setup>
import {onMounted} from 'vue';
import {useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {PRO_SIDEBAR_ITEMS} from '@/constants/pro-profile';

import CrossgridDashboardLayout from '@/components/_layout/CrossgridDashboardLayout.vue';
import DashboardSidebar from '@/components/organisms/CrossgridSidebar.vue';

const router = useRouter();

onMounted(() => {
  const onboarded = localStorage.getItem('onboarded');
  if (onboarded) {
    router.push(CROSSGRID_PAGE_URLS.pro.dashboard.availability);
  }
  localStorage.setItem('onboarded', 'true');
});
</script>
