import {ref, Ref} from 'vue';

import {APIStandardError} from '@/types/network';
import {ProService} from '@/types/pro-services';

import {useHttp} from './composables';

export const errors: Ref<APIStandardError | undefined> = ref(undefined);

const http = useHttp();

export const searchProService = async (query: string): Promise<ProService[]> => {
  const response = await http
    .post('/pro/pro_service/search/', {
      query: query,
    })
    .catch(error => {
      errors.value = error.response?.data;
    });
  return response?.data.items;
};
